/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { App } from 'containers/App';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { getConfig } from 'utils/config/getConfig';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const main = async () => {
  if ((process.env.MOCK_API as unknown as boolean) === true) {
    const { mockServiceWorker, onUnhandledRequest } = await import(
      './testData/msw/mockServiceWorker'
    );
    await mockServiceWorker.start({ onUnhandledRequest });
    console.groupCollapsed('[MSW] Loaded with handlers 🥳');
    mockServiceWorker.listHandlers().forEach((handler) => {
      if ('info' in handler) console.log(handler.info.header);
    });
    console.groupEnd();
  }

  const env = getConfig().ENVIRONMENT;
  const { initGA } = useGoogleAnalytics();
  if (env === 'prd') {
    Sentry.init({
      dsn: 'https://4c8b7ae027283bf164161b8796b5c9b1@o4505352327528448.ingest.sentry.io/4506026344382464',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      environment: env, // Set an environment incase we decide to start logging dev errors in the future

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    initGA();
  }

  const container = document.querySelector('#root');
  const root = createRoot(container!);
  root.render(<App />);
};

main().then();
