import { cloneElement, JSX, useEffect, useRef } from 'react';

type InfiniteScrollerProps = {
  Element?: React.ElementType;
  loadMore: () => void;
  loader?: JSX.Element;
  hasMore: boolean;
  threshold?: number;
  children: React.ReactNode;
};

export const InfiniteScroller = ({
  Element = 'div',
  loadMore,
  loader,
  hasMore,
  threshold = 100,
  children,
}: InfiniteScrollerProps) => {
  const loaderRef = useRef(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting && hasMore) {
      if (entry.intersectionRatio > 0.95) {
        loadMore();
      }
    }
  };

  /* Because we need to tackle the loader component dynamically
     Component needs to be compatible with a ref, so if it's a function component
     make sure it's using forwardRef, we then wrap it with forwardRef
     and pass the ref to the component
   */
  const renderLoader = () => {
    if (hasMore && loader) {
      return cloneElement(loader, { ref: loaderRef });
    }
    return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `${threshold}px`,
      threshold: 0.95,
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, threshold, hasMore, loadMore]);

  return (
    <Element>
      {children}
      {renderLoader()}
    </Element>
  );
};
