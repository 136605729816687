import { styled } from '@mui/material';
import useSort, { SortBy, SortOrder } from 'hooks/useSort';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import colours from 'utils/theme/colours';
import { JSX } from 'react';

interface StyleProps {
  isActive: boolean;
}

const StyledSortIndicator = styled('div')<StyleProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) =>
    props.isActive ? colours.primary.dark : colours.secondary.main};
  font-weight: ${(props) => (props.isActive ? 800 : 400)};

  :hover {
    cursor: pointer;
  }
`;

const StyledArrowIcon = styled('span')<StyleProps>`
  user-select: none;
  display: flex;
  svg path {
    fill: ${(props) =>
      props.isActive ? colours.primary.main : colours.secondary.main};
  }
`;

interface Props {
  sortKey: SortBy;
  children: JSX.Element;
  label: string;
}

export const SortIndicator = ({ sortKey, children, label }: Props) => {
  const { setSort, order, sortBy } = useSort();
  const isActive = sortBy === sortKey;

  const handleSortIndicatorClick = () => {
    setSort({
      sortBy: sortKey,
      order:
        isActive && order === SortOrder.Descending
          ? SortOrder.Ascending
          : SortOrder.Descending,
    });
  };
  const getSortIndicatorLabel = () => {
    if (!isActive) {
      return `${label} sort indicator`;
    }
    return `${label} sort indicator, active, ${
      order === SortOrder.Ascending ? 'ascending' : 'descending'
    }`;
  };

  return (
    <StyledSortIndicator
      tabIndex={0}
      aria-label={getSortIndicatorLabel()}
      data-testid="sort-indicator"
      isActive={isActive}
      onClick={handleSortIndicatorClick}
      onKeyDown={(e) => e.key === 'Enter' && handleSortIndicatorClick()}
    >
      {children}
      <StyledArrowIcon isActive={isActive}>
        {isActive && order === SortOrder.Ascending ? (
          <ExpandLess titleAccess="up arrow icon" fontSize="small" />
        ) : (
          <ExpandMore titleAccess="down arrow icon" fontSize="small" />
        )}
      </StyledArrowIcon>
    </StyledSortIndicator>
  );
};
